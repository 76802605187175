body {
  background-color: #101214;
  display: flex;
  flex-direction: row;



  font-size: calc(10px + 2vmin);
  color: rgb(199, 199, 199);
}

.gamecard {
  margin-left: 10px;
}

.games {
  margin-left: 130px;
}

.catagories button:hover {
  cursor: pointer;
  background-color: rgb(0, 140, 255);

}

.games {
  width: 700px;
}

.individualgamelink .as .Text {
  display: flex;
  flex-direction: column;

}

.Text {
  margin-left: 10px;

}

.individualgamelink .as .Text {
  color: white;



}

.messagebord {
  margin-left: 130px;
  display: flex;
  flex-direction: column;
}

.messageContainter .contacts {
  width: 300px;
}

.messageContainter .chat {
  width: 400px;

}

.mainchatbox {
  width: 85%;
  height: 90%;

  background: rgb(105, 167, 238);
  border-radius: 20px;
}

.sender {
  display: flex;
  flex-direction: row;


}

.reciever {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin-left: 150px;
}

.reciever p {
  font-size: 15px;

  width: 300px;
  background: linear-gradient(to right, #053ffa -42%, #c007c0 100%);

  overflow-wrap: break-word;

  color: rgb(255, 255, 255);
  border-radius: 20px;
  border-bottom-right-radius: 0px;
  padding: 5px;
  padding-top: 10px;
}

.sender p {
  font-size: 15px;

  width: 300px;
  background-color: #2e2e2e;
  overflow-wrap: break-word;

  color: rgb(255, 255, 255);
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  padding: 5px;
  padding-top: 10px;
}

.sender img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  align-self: flex-end;
}

.reciever img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  align-self: flex-end;
}

.sendmessage {
  display: flex;
  flex-direction: row;
}


.sendmessage img {
  width: 40px;
  height: 40px;
  border-radius: 50px;

}

.chatbox {

  background: linear-gradient(to right, #1f1f1f -42%, #1b1b1b 100%);
  width: 504px;
  height: 700px;

  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-right: 20px;

  align-items: flex-start;

  margin: 0;
  margin-top: 13px;
  margin-left: 20px;
  justify-content: space-between;
  border-radius: 30px;
}

.ics {
  background: linear-gradient(to right, #053efa28 -42%, #c007c027 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 30px;
  width: 40px;
  transform: rotate(40deg);



}

.sendmessage input {
  width: 400px;
  background-color: #64606048;
  border: none;
  padding-left: 20px;
  color: white;
  margin-left: 5px;
  border-radius: 20px;
}

.messageContainter {
  display: flex;
  flex-direction: row;
}

.gamings {
  margin-left: 140px;
}


:any-link {
  color: rgb(221, 221, 221);
  text-decoration: none;
}

.individualgamelink .as .Text p {
  width: 500px;
  text-decoration: none;
  font-size: 14px;
  margin-top: 0;
}

.individualgamelink .as .Text h3 {
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 5;

}



.individualgamelink .as {
  display: flex;
  flex-direction: row;
  border: rgba(54, 53, 53, 0.322) 0.5px solid;
  margin-top: 10px;
  margin-bottom: 0px;
  border-radius: 20px;
  padding-left: 5px;
}

.individualgamelink .as:hover {
  cursor: pointer;
  border: rgba(1, 106, 138, 0.267) 0.5px solid;


}

.Pagination {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 100px;
}

.Pagination :hover {
  cursor: pointer;
  background-color: rgb(0, 153, 255);
}

.Pagination .Pre:hover {
  background-color: transparent;
}

.Pagination .num:hover {
  background-color: transparent;
}



.Pagination button {
  border: none;
  margin-left: 5px;
  width: 60px;
  height: 30px;
  background-color: #2e2e2e;
  font-weight: 2px;
  color: white;
  border-radius: 3px;
}

.individualgamelink .as img {
  width: 80px;
  height: 80px;
  border-radius: 13px;
}



.games p {
  margin-bottom: 2px;
}

.videolist {
  margin-left: 130px;
}

.Videocard {
  background: linear-gradient(to right, #2a2a2b -42%, #2b2a2a 100%);
  border-radius: 10px;
  width: 410px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
  margin-top: 10px;


}

iframe {

  width: 400px;
  height: 200px;
  margin-bottom: 0px;



}

.videolists {
  display: flex;
  margin-left: 10px;
  flex-direction: row;
  flex-wrap: wrap;
}

.profiletext {

  display: flex;
  flex-direction: row;
  align-self: flex-start;


  align-items: center;
  color: white;
  border-top: rgba(255, 255, 255, 0.11) 1px solid;
  padding: 5px;
  margin-left: 5px;
  width: 390px;
  margin-top: 10px;



}

.profiletext img {
  width: 40px;
  height: 40px;
  border-radius: 20px;

}

.profiletext p {
  font-size: 15px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: bold;
}

.catagories button {
  border: none;
  margin-left: 10px;
  width: 60px;
  height: 30px;
  background-color: #2e2e2e;
  font-weight: 2px;
  color: white;
  margin-top: 5px;
  border-radius: 3px;
}

.catagories {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.all {
  display: flex;
  flex-direction: row;
}

.user .popup {
  background: linear-gradient(to right, #444444 -42%, #2e323d 100%);
  height: 200px;
  width: 58px;
  top: 36px;
  padding-top: 20px;
  right: 20px;
  flex-direction: column;
  border-radius: 20px;

  display: flex;
  align-items: flex-start;



  position: absolute;
}

.sellcard {

  width: 280px;
  height: 250px;
  background: linear-gradient(to right, #45454b -42%, #202020 100%);
  margin-top: 30px;
  border-radius: 12px;
  margin-bottom: 30px;
  margin-right: 10px;

}

.popup .Iconss :hover {
  color: rgb(255, 255, 255);
  cursor: pointer;
}


.popup .Iconss {
  color: rgb(90, 90, 90);
  margin-top: 10px;
  background: linear-gradient(to top, #000000d0 -42%, #3b3a3a 100%);
  display: flex;
  align-items: center;

  justify-content: center;
  width: 50px;
  height: 50px;
  margin-left: 5px;
  border-radius: 20px;


}

.sellcard p {
  margin-left: 15px;
  margin-top: 12px;
  font-size: 17px;
  font-weight: bold;

}

.cardtop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}

.sellcardimg {
  display: block;
}

.sellcardimgbackground {
  background-color: rgba(255, 255, 255, 0.151);
  width: 160px;
  height: 80px;
  margin-left: 60px;
  transform: rotate(-55deg);
  margin-top: 50px;


  border-radius: 30px;

}

.detailtext p {
  font-size: 14px;
  text-align: center;
  text-align: left;
  margin-left: 10px;
  padding-top: 10px;
}

.buttonsdownload button {
  border: none;
  width: 120px;
  border-radius: 8px;
}

.buttonsdownload button:hover {
  cursor: pointer;
  background-color: rgb(0, 162, 255);
}

.iconn {
  margin-bottom: -7px;
}

.Icons :hover {
  cursor: pointer;
  background-color: rgb(0, 162, 255);

}

.gamecard :hover {
  cursor: pointer;
  opacity: 0.5;

}

.iconn :hover {
  cursor: pointer;
  color: rgb(0, 162, 255);
}

.sellcard .cardtop .iconsright {


  z-index: 2;




}

.sellcard .cardtop .iconsright :hover {
  cursor: pointer;

  color: rgb(0, 162, 255);
}

.buttonsdownload {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  color: white;
  padding: 20px;

}

table tr {
  font-size: 14px;


}

table {
  margin-left: 10px;


}

.spacification {
  height: 200px;

}

table tr th {

  padding-left: 50px;
}

.gamedetail {
  height: 350px;
  width: 700px;
  background: linear-gradient(to right, #45454b -42%, #202020 100%);



  margin-top: 20px;


  border-radius: 30px;
}

.GameBanners {
  height: 200px;
  width: 700px;
  background-size: 92%;


  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  border-radius: 30px;
}

.icon :hover {
  cursor: pointer;
}

.userpic :hover {
  cursor: pointer;
}

.Librarycard p {
  margin-left: 15px;
  margin-top: 12px;
  font-size: 17px;
  font-weight: bold;
}

.Downloadlist .profile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.userprofile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 2px;
  border: 2px transparent solid;
}

.userprofile:hover {
  border: 2px rgba(0, 107, 230, 0.534) solid;
  cursor: pointer;
  border-radius: 10px;


}

.Downloadlist .downloadbutton button {
  background-color: transparent;
  color: rgb(134, 134, 134);
  border: rgb(151, 150, 150) 1px solid;
  height: 35px;
  border-radius: 25px;
  width: 100px;
  justify-self: flex-end;
  margin-right: 5px;
}

.Downloadlist .downloadbutton button:hover {
  color: rgb(10, 108, 236);
  border: rgb(5, 93, 224) 1px solid;
}

.Downloadlist .profilepic img {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  border-radius: 12px;

}

.userprofile .profilepic img {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  border-radius: 12px;

}

.Downloadlist p {
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
}

.userprofile p {
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;

}

.Downloadlist {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lastBody {
  display: flex;
  flex-direction: row;


}

.Librarycard {
  width: 412px;
  height: 250px;
  background: linear-gradient(to right, #45454b -42%, #202020 100%);
  margin-top: 30px;
  border-radius: 12px;
  margin-bottom: 30px;
}


.sellcardimg img {
  width: 300px;
  height: 300px;
  left: 2px;
  top: 3px;
  margin-top: -190px;
  z-index: 1;
  position: relative;


  padding: 0;
}

.gamecard img {
  width: 200px;
  height: 100px;
  border-radius: 20px;
}

.gamecard p {
  font-size: 15px;
  margin-left: 10px;
  margin-top: 0;
  font-weight: bold;
  margin-bottom: 0;

}

.gameList {
  display: flex;

  flex-direction: row;
}

.navbutton {
  background-color: transparent;
  height: 50px;
  width: 50px;
  display: flex;
  border-radius: 7px;
  font-size: 20px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
}

.navbuttona,
.navbutton:hover {
  background-color: rgb(0, 110, 255);
  height: 50px;
  width: 50px;
  display: flex;
  border-radius: 7px;
  font-size: 20px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;

}

.navbar {
  width: 80px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: 5px;
  border-right: rgba(90, 90, 90, 0.301) 1px solid;
  position: fixed;

}

.navbar .logo {
  width: 20px;
  height: 20px;

}


.navbartext {
  margin-top: 160px;
}

.Mbody {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-left: 120px;

  width: 720px;

}

.Search {
  width: 300px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  background-color: #141414;
  padding-left: 10px;
  padding-right: 20px;
  border-radius: 18px;
}

.Banners {
  height: 200px;
  width: 700px;
  background: linear-gradient(to top, #45454b -42%, #000000 100%);

  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  border-radius: 30px;
}

.bannerimage {
  margin-top: -15px;

}

.bannerimage img {
  padding-bottom: 0px;
  width: 250px;
  height: 250px;
}

.Banners button {
  width: 150px;
  height: 40px;
  background-color: rgb(0, 119, 255);
  color: white;
  font-weight: bold;
  font-size: 17px;
  border: none;
  border-radius: 10px;


}

.Banners .Text .Sign :hover {
  background-color: rgba(0, 53, 114, 0.747);
  cursor: pointer;
}

.Banners Text {
  color: white;
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: 0;
}

.Banners Text p {
  font-size: 20px;
  font-weight: bold;

}


.Search input {
  background-color: transparent;
  color: white;
  border: none;
}

.Search input {
  color: white;
  border: none;
  width: 100%;
}

.rightbody {
  display: flex;
  flex-direction: column;
  margin-top: 14px;

  width: 377px;
  margin-left: 46px;
  margin-bottom: 30px;
}

.contactlist .cardtop {
  margin-left: 20px;
  margin-right: 20px;

}

.contactlist .cardtop.p {
  margin-bottom: 0;
}

.contactlist.online {
  margin-top: 0px;

}

.contactlist p {
  font-size: 18px;
  font-weight: bold;

}

.contactlist {
  background: linear-gradient(to right, #38383d -42%, #292929 100%);
  height: 100%;
  margin-top: 15px;
  border-radius: 50px;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.icon {
  margin-right: 10px;
}

.rightbody .user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;



}

.rightbody .user img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-left: 5px;
}